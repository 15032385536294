import { createI18n } from 'vue-i18n';

// Définissez vos traductions ici
const messages = {
  en: {
    welcome: 'Welcome',
    button: {
      submit: 'Submit',
      cancel: 'Cancel',
      disconnect: 'Disconnect',
      go_to_website: 'Go To Website',
    },
    // ajoutez d'autres messages ici
    cards: {
      bookings : 'Bookings',
      services : 'Services',
      reviews : 'Reviews',
      notifications : 'Notifications' ,

    },
    // Dashboard menu
    side_nav_menu: {
      dashboard : 'Dashboard',
      booking_list : 'Booking List',
      services : 'Services',
      add_service : 'Add' ,
      edit_service : 'Edit' ,
      profile : 'Profile',
      chat : 'Chat',
      accounting : 'Accounting',
      subscriptions : 'Subscriptions',
      wallet : 'Wallet',
      payments : 'Payments',
      reviews : 'Reviews',

    },
  },
  fr: {
    welcome: 'Bienvenue',
    button: {
      submit: 'Soumettre',
      cancel: 'Annuler',
      disconnect: 'Déconnexion',
      go_to_website: 'Site Web',
    },
    // ajoutez d'autres messages ici
    cards: {
      bookings : 'Réservations',
      services : 'Services',
      reviews : 'Avis',
      notifications : 'Notifications' ,

    },
    // Dashboard menu
    side_nav_menu: {
      dashboard : 'Tableau de bord',
      booking_list : 'Rendez-vous',
      services : 'Services',
      add_service : 'Ajouter', 
      edit_service : 'Modifier' ,
      profile : 'Profil',
      chat : 'Disscussions',
      accounting : 'Comptabilité',
      subscriptions : 'Abonnements',
      wallet : 'Portefeuille',
      payments : 'Paiements',
      reviews : 'Avis',
    },
  }
};

const i18n = createI18n({
  locale: 'en', // langue par défaut
  fallbackLocale: 'en', // langue de secours
  messages,
});

export default i18n;
